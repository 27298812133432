import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Right Home Estate Agents
			</title>
			<meta name={"description"} content={"Turning Houses into Homes"} />
			<meta property={"og:title"} content={"Right Home Estate Agents"} />
			<meta property={"og:description"} content={"Turning Houses into Homes"} />
			<meta property={"og:image"} content={"https://veloriumi.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://veloriumi.com/images/1325477832.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://veloriumi.com/images/1325477832.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://veloriumi.com/images/1325477832.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://veloriumi.com/images/1325477832.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://veloriumi.com/images/1325477832.png"} />
			<meta name={"msapplication-TileImage"} content={"https://veloriumi.com/images/1325477832.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						font="--base"
						letter-spacing="2px"
					>
						Experience Excellence in Real Estate
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Our Story
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				Founded on the belief that everyone deserves a place to call their own, Right Home Estate Agents has grown from a small agency into a prominent leader in real estate services. We began with a simple mission: to simplify the real estate process and make it accessible to all. Over the years, we have helped countless clients find their perfect homes, sell properties at great value, and secure ideal rentals. Our journey is built on trust, hard work, and an unwavering commitment to our clients.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://veloriumi.com/images/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Our Team
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					At the core of Right Home Estate Agents is our dynamic team of real estate professionals. Each member brings a wealth of expertise and an infectious enthusiasm for real estate. Our agents are not just skilled negotiators and market analysts, they are your neighbors and community members who understand the importance of a home to your life's story.
					</Text>
				</Box>
				<Image src="https://veloriumi.com/images/5.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://veloriumi.com/images/6.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					Why Choose Right Home Estate Agents
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Join us at Right Home Estate Agents, where your real estate aspirations become our mission. We believe in creating relationships for life, not just transactions. By choosing us, you're not just finding a property – you're crafting your story with a team that values your dreams and works tirelessly to see them realized.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-29">
			<Override
				slot="SectionContent"
				max-width="1440px"
				align-items="flex-start"
				flex-direction="row"
				flex-wrap="wrap"
				md-flex-direction="column"
				sm-min-width="280px"
			/>
			<Box width="20%" sm-width="100%" md-margin="0px 0px 36px 0px">
				<Text margin="0px 0px 0px 0px" font="--lead" color="--dark" sm-margin="0px 0px 20px 0px">
				What Sets Us Apart
					<br />
				</Text>
			</Box>
			<Box width="80%" sm-width="100%" md-width="100%">
				<Box
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
				>
					<Text color="--dark" letter-spacing="-.7px" margin="0px 0px 8px 0px" font="--headline3">
					Client-Centric Approach:  
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					We listen to your needs and preferences to ensure our services align perfectly with your expectations.
					</Text>
				</Box>
				<Box
					margin="0px 0px 50px 0px"
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Transparent Processes:  
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Every step of your real estate journey is clear and straightforward, with no surprises along the way.
					</Text>
				</Box>
				<Box
					width="100%"
					border-color="--color-lightD2"
					border-style="solid"
					border-width="0px 0px 1px 0px"
					margin="0px 0px 50px 0px"
				>
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Innovative Solutions: 
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					We leverage the latest technology and strategies to provide you with the best possible outcomes.
					</Text>
				</Box>
				<Box width="100%" border-color="--color-lightD2" margin="0px 0px 0px 0px">
					<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark" letter-spacing="-.7px">
					Commitment to Satisfaction: 
					</Text>
					<Text margin="0px 0px 38px 0px" font="--base" color="--dark" letter-spacing="-.7p">
					Your happiness is our success. We strive to exceed expectations in every interaction.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});